import React from 'react'

import './footer.scss'

const Footer = () => (
  <div className='footer-background'>
    <div className='footer-container page-container'>
      <div>© 2020 HYGIENZ. All rights reserved.</div>
      <div>
        <small>
          Website built by
          <a href='http://thehype.nz' target='_blank' rel='noopener noreferrer'>
            The Hype - Digital Agency
          </a>
        </small>
      </div>
    </div>
  </div>)

export default Footer
